import LastSeenHotelList from '@lmt-rpb/LastSeenHotelList/LastSeenHotelList.vue';
import { loadLocalStorageHotel, editPersistedLastSeenHotels } from '@services/lastSeenHotelService';
import type { LastSeenHotel } from '@/interfaces/localStorage/last-seen-hotel';
import { createApp } from 'vue';
import { mountReplace } from '../utils/mountUtils';

function initComponent(expectedHotelCount: number, hotelList: Promise<LastSeenHotel[]>) {
	const lastSeenHotelsSection = document.querySelector('.rpb_last-seen') as HTMLElement;
	if (lastSeenHotelsSection && expectedHotelCount) {
		const heading =
			expectedHotelCount > 1 ? lastSeenHotelsSection.dataset.headlinePlural : lastSeenHotelsSection.dataset.headlineSingular;
		const isTenantDE = !!lastSeenHotelsSection.dataset.istenantde;
		const app = createApp(LastSeenHotelList, {
			expectedHotelCount,
			lastSeenHotelsPromise: hotelList,
			heading,
			showSuns: isTenantDE,
		});

		mountReplace(app, lastSeenHotelsSection);
	}
}

const persistedHotels = loadLocalStorageHotel();
const lastSeenHotelsPromise = editPersistedLastSeenHotels(persistedHotels.items);
initComponent(persistedHotels.items.length, lastSeenHotelsPromise);
