import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-label", "title", "href"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: 0,
  "aria-hidden": "true",
  class: "image-card__image"
}
const _hoisted_4 = ["src", "alt"]

import { computed, ref } from 'vue';

interface Props {
	bgImage?: string;
	bgImageAlt?: string;
	title: string;
	href: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageCard',
  props: {
    bgImage: {},
    bgImageAlt: {},
    title: {},
    href: {}
  },
  setup(__props: any) {



const hasBackgroundImage = computed(() => !!__props.bgImage);
const blockModifiers = computed(() => ({
	'image-card--is-loaded': imageLoaded.value,
}));

const imageLoaded = ref(false);

const onImageLoad = () => {
	imageLoaded.value = true;
};
const onImageError = () => {
	imageLoaded.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(['image-card', blockModifiers.value]),
    "aria-label": _ctx.title,
    title: _ctx.title,
    href: _ctx.href
  }, [
    _createElementVNode("p", {
      class: "image-card__title",
      textContent: _toDisplayString(_ctx.title)
    }, null, 8 /* PROPS */, _hoisted_2),
    (hasBackgroundImage.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.bgImage,
            alt: _ctx.bgImageAlt,
            "aria-hidden": "true",
            onLoad: onImageLoad,
            onError: onImageError
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4)
        ]))
      : _createCommentVNode("v-if", true)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}
}

})