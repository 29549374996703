<template>
	<section class="last-seen-hotel-list">
		<div
			v-if="expectedHotelCount && (!lastSeenHotels || lastSeenHotels.length > 0)"
			class="last-seen-hotel-list__wrapper"
		>
			<h2 class="last-seen-hotel-list__heading">{{ heading }}:</h2>
			<ul class="last-seen-hotel-list__listing">
				<LastSeenHotelItem
					v-for="hotel in lastSeenHotels"
					:key="hotel.url"
					:title="hotel.title"
					:image="hotel.image"
					:url="hotel.url"
					:region="hotel.region"
					:hotel-rating="hotel.hotelRating"
					:show-suns="showSuns"
				/>
				<template v-if="!lastSeenHotels">
					<li
						v-for="index in expectedHotelCount"
						:key="index"
					>
						<SkeletonLoader
							width-unit="rem"
							:loader-width="35"
							:loader-height="11"
							border-radius="1rem"
							class="last-seen-hotel-list__item-loading"
						/>
					</li>
				</template>
				<li class="last-seen-hotel-list__spacer"></li>
			</ul>
			<Gradient
				:height="isMobileLarge ? '10.2' : '12.2'"
				width="4"
				:top="isMobileLarge ? '4.9' : '5.4'"
			/>
		</div>
	</section>
</template>

<script lang="ts" setup>
import LastSeenHotelItem from '@lmt-rpb/LastSeenHotelItem/LastSeenHotelItem.vue';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import Gradient from '@lmt-rpb/Atoms/Gradient/Gradient.vue';
import { onBeforeMount, ref } from 'vue';
import type { LastSeenHotelType } from './types';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	expectedHotelCount: number;
	lastSeenHotelsPromise: Promise<LastSeenHotelType[]>;
	heading?: string;
	showSuns?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	heading: 'Zuletzt gesehen',
	showSuns: false,
});

const lastSeenHotels = ref<LastSeenHotelType[] | null>(null);
const { isMobileLarge } = storeToRefs(useBreakpointStore());

onBeforeMount(() => {
	props.lastSeenHotelsPromise.then((hotels) => {
		lastSeenHotels.value = hotels;
	});
});
</script>

<style lang="scss" scoped>
section {
	background-color: $color-white;
	width: 100%;
	overflow-y: hidden;
	position: relative;
	margin-bottom: $vgrid-mobile-large;
}

.last-seen-hotel-list {
	&__item-loading {
		height: 10rem !important;
	}
}

.last-seen-hotel-list__listing {
	@include customHorizontalScrollbar;

	display: flex;
	justify-content: flex-start;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	padding: 1rem;
	margin: 0 0.4rem;
	min-height: 12rem;
	max-width: 150rem;
	list-style-type: none;
	gap: 0.8rem;
}

.last-seen-hotel-list__wrapper {
	margin: 0 auto;
	max-width: 150rem;
}

.last-seen-hotel-list__spacer {
	min-width: 1rem;
	list-style-type: none;
}

.last-seen-hotel-list__heading {
	color: $color-text;
	font-size: $font-medium-3;
	font-weight: $font-weight-regular;
	padding: 0;
	text-align: left;
	margin: 1.4rem 0 0 1.4rem;
}

@media (min-width: $breakpoint-verysmall) {
	.last-seen-hotel-list {
		&__heading {
			font-size: $font-medium-1;
		}

		&__overlay {
			height: 12.2rem;
			top: 5.3rem;
			width: 4rem;
		}

		&__item-loading {
			height: 12rem !important;
		}
	}
}

@media (min-width: $breakpoint-verylarge) {
	.last-seen-hotel-list {
		&__overlay {
			display: none;
			overflow-x: hidden;
			-webkit-overflow-scrolling: initial;
			margin: 0 1rem 0 0;
		}

		&__listing {
			overflow-x: visible;
			margin: 0.4rem 0 0.4rem 2.1rem;
			gap: 1.2rem;
		}

		&__heading {
			margin: 1.4rem 0 0 3rem;
		}
	}
}
</style>
