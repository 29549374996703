import { createApp } from 'vue';
import { mountReplace } from '../utils/mountUtils';
import ImageCardGrid from '@lmt-rpb/Molecules/ImageCardGrid/ImageCardGrid.vue';

const mountPoint = document.querySelector<HTMLElement>('.image-cards-typo3');

function initImageCardsPlugin() {
	const dataset = mountPoint?.dataset;
	const imageCards = JSON.parse(dataset?.imageCards ?? '[]');
	if (!imageCards.length) return;
	const app = createApp(ImageCardGrid, {
		imageCards: imageCards,
	});
	if (mountPoint) mountReplace(app, mountPoint);
}

if (mountPoint) {
	initImageCardsPlugin();
}
