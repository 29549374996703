<template>
	<div
		class="image-card-grid rpb_container"
		role="navigation"
		:aria-label="title"
	>
		<div
			v-if="!isDesktop"
			class="image-card-grid__notice--mobile"
		>
			<slot name="promotion-notice" />
		</div>
		<div class="image-card-grid__cards">
			<div
				v-if="isDesktop"
				class="image-card-grid__notice--desktop"
			>
				<slot name="promotion-notice" />
			</div>
			<ImageCard
				v-for="imageCard in imageCards"
				:key="imageCard.link + imageCard.title"
				:bg-image="imageCard.image"
				:bg-image-alt="imageCard.title"
				:href="imageCard.link"
				:title="imageCard.title"
				data-ga-event="select_content"
				data-ga-content_type="ux_plugin_image_card_click"
				:data-ga-content_id="imageCard.title"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import ImageCard from '@lmt-rpb/Atoms/ImageCard/ImageCard.vue';
import { computed } from 'vue';

interface ImageCardItem {
	image?: string;
	link: string;
	title: string;
}

interface Props {
	cols?: number;
	title?: string;
	imageCards: ImageCardItem[];
	isDesktop?: boolean;
}
const { cols = 5, title, isDesktop = false } = defineProps<Props>();
const _cols = computed(() => Math.max(1, cols));
</script>

<style lang="scss" scoped>
.image-card-grid {
	display: flex;
	flex-direction: column;
	gap: 0;
	gap: 0.8rem;
	padding: 1.6rem 0.8rem 0.4rem;

	&__cards {
		display: flex;
		align-items: flex-start;
		overflow-x: scroll;
		scroll-behavior: smooth;
		position: relative;
		gap: 0.8rem;
		padding: 0.4rem;

		@include horizontal-hidden-scroll;

		& > * {
			flex-shrink: 0;
		}
	}

	&__notice--mobile {
		padding: 0.4rem 0.8rem;

		&:empty {
			display: none;
		}
	}

	@include media-query-up($breakpoint-small) {
		gap: 0.8rem;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		padding-top: 2.4rem;

		&__cards {
			gap: 1.6rem;
		}
	}

	@include media-query-up($breakpoint-extralarge) {
		&__notice {
			&--desktop {
				grid-column: 1 / span 2;

				&:empty {
					display: none;
				}
			}
		}

		&__cards {
			display: grid;
			justify-content: center;
			align-items: stretch;
			grid-template-columns: repeat(v-bind(_cols), 1fr);
			width: 100%;
			overflow-x: visible;

			& > * {
				width: auto !important;
				flex-shrink: 0;
			}
		}
	}

	@include media-query-up(calc($breakpoint-container + 3.3rem)) {
		// 3.3rem is the padding on the left and right
		padding-left: 0;
		padding-right: 0;
	}
}
</style>
