import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  key: 0,
  class: "image-card-grid__notice--mobile"
}
const _hoisted_3 = { class: "image-card-grid__cards" }
const _hoisted_4 = {
  key: 0,
  class: "image-card-grid__notice--desktop"
}

import ImageCard from '@lmt-rpb/Atoms/ImageCard/ImageCard.vue';
import { computed } from 'vue';

interface ImageCardItem {
	image?: string;
	link: string;
	title: string;
}

interface Props {
	cols?: number;
	title?: string;
	imageCards: ImageCardItem[];
	isDesktop?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ImageCardGrid',
  props: {
    cols: { default: 5 },
    title: {},
    imageCards: {},
    isDesktop: { type: Boolean, default: false }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "2b85128a": (_cols.value)
}))


const _cols = computed(() => Math.max(1, __props.cols));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "image-card-grid rpb_container",
    role: "navigation",
    "aria-label": _ctx.title
  }, [
    (!_ctx.isDesktop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "promotion-notice")
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isDesktop)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "promotion-notice")
          ]))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageCards, (imageCard) => {
        return (_openBlock(), _createBlock(ImageCard, {
          key: imageCard.link + imageCard.title,
          "bg-image": imageCard.image,
          "bg-image-alt": imageCard.title,
          href: imageCard.link,
          title: imageCard.title,
          "data-ga-event": "select_content",
          "data-ga-content_type": "ux_plugin_image_card_click",
          "data-ga-content_id": imageCard.title
        }, null, 8 /* PROPS */, ["bg-image", "bg-image-alt", "href", "title", "data-ga-content_id"]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 8 /* PROPS */, _hoisted_1))
}
}

})