<template>
	<a
		:class="['image-card', blockModifiers]"
		:aria-label="title"
		:title
		:href
	>
		<p
			class="image-card__title"
			v-text="title"
		/>
		<div
			v-if="hasBackgroundImage"
			aria-hidden="true"
			class="image-card__image"
		>
			<img
				:src="bgImage"
				:alt="bgImageAlt"
				aria-hidden="true"
				@load="onImageLoad"
				@error="onImageError"
			/>
		</div>
	</a>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

interface Props {
	bgImage?: string;
	bgImageAlt?: string;
	title: string;
	href: string;
}

const { bgImage, title, bgImageAlt } = defineProps<Props>();

const hasBackgroundImage = computed(() => !!bgImage);
const blockModifiers = computed(() => ({
	'image-card--is-loaded': imageLoaded.value,
}));

const imageLoaded = ref(false);

const onImageLoad = () => {
	imageLoaded.value = true;
};
const onImageError = () => {
	imageLoaded.value = false;
};
</script>

<style lang="scss" scoped>
.image-card {
	&,
	& *,
	&::before,
	&::after {
		margin: 0;
		padding: 0;
		transition: all 0.2s ease-in;
	}

	& * {
		pointer-events: none;
	}

	position: relative;
	display: block;
	height: 16rem;
	width: 16rem;
	border-radius: $border-radius-large;
	outline: 0.2rem solid transparent !important;
	outline-offset: 0.2rem;
	background-color: $color-primary;
	z-index: 0;

	&--is-loaded {
		background-color: transparent;
	}

	&__image {
		position: absolute;
		inset: 0;
		overflow: hidden;
		border-radius: inherit;

		&::after,
		&::before {
			content: '';
			position: absolute;
			inset: 0;
		}

		&::before {
			z-index: 1;
			background: linear-gradient(0deg, rgba(0, 0, 0, 65%) 20%, rgba(0, 0, 0, 50%) 30%, rgba(0, 0, 0, 5%) 45%, rgba(0, 0, 0, 0%) 65%);
		}

		&::after {
			z-index: 0;
			background: linear-gradient(0deg, rgba(0, 0, 0, 5%) 20%, rgba(0, 0, 0, 5%) 45%, rgba(0, 0, 0, 0%) 65%);
			opacity: 0;
			transition: opacity 0.2s ease-in;
		}

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transform-origin: center;
		}
	}

	&__title {
		position: absolute;
		bottom: 1.6rem;
		left: 1.6rem;
		right: 1.6rem;
		color: $color-white;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-align: left;
		z-index: 3;
	}

	&:focus-visible {
		outline-color: $color-primary !important;
	}

	&:hover {
		background-color: $color-primary-d0;

		.image-card__image {
			img {
				transform: scale(1.02);
			}

			&::after {
				opacity: 1;
			}
		}
	}

	@include media-query-up($breakpoint-small) {
		width: 27.5rem;
	}
}
</style>
